import React from 'react';

const UserContext = React.createContext({
	UserToken: '',
	SetToken: null,
	RemoveCookies: null,
	UserInfo: null,
	SetUserInfo: null,
	StoreInfo: '',
	CompleteLoad: null,
	// last page
	LastPage: '',
	SetLastPage: '',
});

export default UserContext;
