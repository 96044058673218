import { getCookie } from 'cookies-next';

const GetBrandSettings = async (brandId) => {
	try {
		const res = await fetch(`${process.env.BASE_URL2}/v1/getBrandSettingDashboard`, {
			method: 'POST',
			body: JSON.stringify({ brandId })
		});

		const data = await res.json();

		return data;
	} catch (error) {
		console.log(error);
	}
};

const GetMinMaxWithdraw = async (payload) => {
	try {
		const res = await fetch(`${process.env.BASE_URL}/v1/get-list-crypto-withdraw-setting`, {
			method: 'POST',
			headers: {
				Authorization: getCookie('user_token')
			},
			body: JSON.stringify({ payload })
		});

		const data = await res.json();

		return data;
	} catch (error) {
		console.log(error);
	}
};

const GetSwapSettings = async (payload) => {
	try {
		const res = await fetch(`${process.env.BASE_URL}/v1/getList-swap`, {
			method: 'POST',
			headers: {
				Authorization: getCookie('user_token')
			},
			body: JSON.stringify(payload)
		});

		const data = await res.json();

		return data;
	} catch (error) {
		console.log(error);
	}
};

const UpdateMinMaxWithdraw = async (payload) => {
	try {
		const res = await fetch(`${process.env.BASE_URL}/v1/update-withdraw-setting`, {
			method: 'POST',
			headers: {
				Authorization: getCookie('user_token')
			},
			body: JSON.stringify(payload)
		});

		const data = await res.json();

		return data;
	} catch (error) {
		console.log(error);
	}
};

const GetAllArticles = async (payload) => {
	try {
		const res = await fetch(`${process.env.BASE_URL}/v1/getArticleList`, {
			method: 'POST',
			headers: {
				Authorization: getCookie('user_token')
			},
			body: JSON.stringify(payload),
		});

		const data = await res.json();
		return data;
	} catch (error) {
		console.error(error);
	}
};

const CreateArticle = async (articleData) => {
	const res = await fetch(`${process.env.BASE_URL}/v1/createArticle`, {
		method: 'POST',
		headers: {
			Authorization: getCookie('user_token')
		},
		body: articleData
	});
	const data = await res.json();

	return data;
};

const ChangeArticleSequence = async (articleIdList) => {
	const res = await fetch(`${process.env.BASE_URL}/v1/changeArticleSequence`, {
		method: 'POST',
		headers: {
			Authorization: getCookie('user_token')
		},
		body: JSON.stringify({ articleIdList })
	});
	const data = await res.json();

	return data;
};

const DeleteArticle = async (articleId) => {
	const res = await fetch(`${process.env.BASE_URL2}/v1/deleteArticle`, {
		method: 'POST',
		headers: {
			Authorization: getCookie('user_token')
		},
		body: JSON.stringify(articleId)
	});
	const data = await res.json();

	return data;
};

const EditArticle = async (articleData) => {
	const res = await fetch(`${process.env.BASE_URL}/v1/updateArticle`, {
		method: 'POST',
		headers: {
			Authorization: getCookie('user_token')
		},
		body: articleData
	});
	const data = await res.json();

	return data;
};

const saveSwapSetting = async (payload) => {
	try {
		const res = await fetch(`${process.env.BASE_URL}/v1/swap-fee-update`, {
			method: 'POST',
			headers: {
				Authorization: getCookie('user_token')
			},
			body: JSON.stringify(payload)
		});
		const data = await res.json();
		return data;
	} catch (error) {
		console.error(error);
	}
};

const BrandServices = {
	saveSwapSetting,
	GetBrandSettings,
	GetMinMaxWithdraw,
	GetSwapSettings,
	UpdateMinMaxWithdraw,
	CreateArticle,
	ChangeArticleSequence,
	GetAllArticles,
	EditArticle,
	DeleteArticle
};

export default BrandServices;
