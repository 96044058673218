import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import 'moment-timezone';
import UserContextProvider from '../src/context/user.context/user.context.provider';
import { Amplify } from 'aws-amplify';
import 'daisyui/dist/full.css';

import config from '../src/constants/config';
import { ToastContainer, toast } from 'react-toastify';
import BrandServices from '../src/services/api/brand.services';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import '../styles/style.css';
import 'rsuite/dist/rsuite.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'sweetalert2/src/sweetalert2.scss';
import '../styles/custom-rsuite.scss';
// import '../styles/custom-swal.scss';
import '../styles/custom-mui.scss';
import '../styles/globals.scss';
import { useRouter } from 'next/router';
import { getCookie } from 'cookies-next';

// import ErrorTemplate from '../src/components/templates/ErrorTemplate';
// import Layout from '../src/components/templates/Layout/Layout';

const Layout = dynamic(() => import('../src/components/templates/Layout/Layout'), { ssr: false });
const ErrorTemplate = dynamic(() => import('../src/components/templates/ErrorTemplate'), {
	ssr: false
});

Amplify.configure(config.aws_amplify);

function MyApp({ Component, pageProps }) {
	const router = useRouter();
	const brandId = getCookie('user_token') && getCookie('user_token').split('-')[0];

	const [role, setRole] = useState({});
	const [brandConfig, setBrandConfig] = useState({
		brandId: '',
		colors: {
			primary: '#2196f3',
			secondary: '#0069c0',
			accent: '#6ec6ff'
		},
		textColor: {
			primary: '#fefefe',
			secondary: '#575757'
		},
		setting: {
			container: {
				backgroundColor: '#FEFEFE'
			},
			faviconUrl: '',
			logoUrl: ''
		}
	});
	const [newTransaction, setNewTransaction] = useState({});
	const [updateTransaction, setUpdateTransaction] = useState({});

	useEffect(() => {
		if (router.pathname !== '/auth/login' && !brandId) location.href = '/auth/login';
		BrandServices.GetBrandSettings('COPA').then(setBrandConfig);
	}, []);

	pageProps = {
		...pageProps,
		brandConfig,
		setBrandConfig,
		brandId,
		toast,
		role,
		setRole,
		newTransaction,
		setNewTransaction,
		updateTransaction,
		setUpdateTransaction,
		Swal,
		router
	};

	return (
		<UserContextProvider>
			<Layout {...pageProps}>
				<ErrorTemplate />
				<ToastContainer />
				<Component {...pageProps} />
			</Layout>
		</UserContextProvider>
	);
}

export default MyApp;
