import UserContext from './user.context';
import { useState, useEffect } from 'react';
import { getCookie, setCookie, deleteCookie } from 'cookies-next';
import { useRouter } from 'next/router';

// const urlAuth = ['/auth/login', '/auth/register'];

const UserContextProvider = (props) => {
	const [userToken, setUserToken] = useState('');
	const [completeLoad, setCompleteLoad] = useState(false);
	const [userInfo, setUserInfo] = useState(null);
	const [userStore] = useState([]);
	const [lastPage, setLastPage] = useState('/');

	const router = useRouter();

	useEffect(() => {
		setCompleteLoad(false);
		// const user_token = localStorage.getItem("user_token");
		const user_token = getCookie('user_token');

		// const user_cookies = getCookie("user_token", {
		//   ...config.cookies_domain,
		// })?.toString();

		if (user_token && user_token !== '') {
			_setNewCookies(user_token);
			// _getUserInfo(user_cookies);

			if (router.pathname === '/auth/login' || router.pathname === '/auth/register') {
				router.push('/');
			}

			// _relogin(user_cookies);
		} else if (router.pathname !== '/auth/login' && router.pathname !== '/auth/register') {
			router.push('/auth/login');
			router.reload();
		}
		setCompleteLoad(true);
	}, [userToken]);

	//   const _relogin = async (cookie) => {
	//     setCompleteLoad(false);
	//     try {
	//       const relogin = (
	//         await axios.get("api/auth/relogin", {
	//           headers: {
	//             Authorization: `Bearer ${cookie}`,
	//           },
	//         })
	//       ).data.data;

	//       if (relogin.length !== 0) {
	//         _setToken(relogin.token);
	//       }
	//     } catch (error) {
	//       _removeCookies();
	//     }
	//   };

	// const _getUserInfo = async (token) => {
	// 	setCompleteLoad(false);
	// 	try {
	// 		const user = await AuthServices.UserAuthData({ token: token });

	// 		if (user) {
	// 			setUserInfo(user);
	// 			_setNewCookies(token);
	// 		}
	// 	} catch (error) {
	// 		_removeCookies();
	// 	}
	// };

	const _setNewCookies = (token) => {
		const ms = new Date().getTime();
		const hour = 120 * 60 * 1000;
		setCompleteLoad(false);
		setCookie('user_token', token, { expires: new Date(ms + hour) });
		// localStorage.setItem("user_token", token);
		// setCookie("user_token", token, {
		//   ...config.cookies_domain,
		//   maxAge: 30 * 24 * 60 * 60,
		// });
		setUserToken(token);
		setCompleteLoad(true);
	};

	// Login Session

	const _setToken = (token) => {
		if (token) {
			setCompleteLoad(false);
			_setNewCookies(token);
			// _getUserInfo(token);
		} else {
			_removeCookies();
		}
	};

	const _removeCookies = async () => {
		setCompleteLoad(false);

		// await Auth.signOut();

		setUserToken('');

		// localStorage.removeItem("user_token");
		deleteCookie('user_token');

		// removeCookies("user_token", {
		//   ...config.cookies_domain,
		// });

		setCompleteLoad(true);
		// router.reload(window.location.pathname);
	};

	return (
		<UserContext.Provider
			value={{
				UserToken: userToken,
				SetToken: _setToken,
				RemoveCookies: _removeCookies,
				CompleteLoad: completeLoad,
				UserInfo: userInfo,
				SetUserInfo: setUserInfo,
				StoreInfo: userStore,
				LastPage: lastPage,
				SetLastPage: setLastPage
			}}>
			{props.children}
		</UserContext.Provider>
	);
};

export default UserContextProvider;
