const config = {
	website: {
		name: 'W1N',
	},
	aws_amplify: {
		aws_appsync_graphqlEndpoint:
      process.env.NEXT_PUBLIC_AWS_APPSYNC_GRAPHQLENDPOINT,
		aws_appsync_region: process.env.NEXT_PUBLIC_AWS_APPSYNC_REGION,
		aws_appsync_authenticationType:
      process.env.NEXT_PUBLIC_AWS_APPSYNC_AUTHENTICATIONTYPE,
		aws_appsync_apiKey: process.env.NEXT_PUBLIC_AWS_APPSYNC_APIKEY,
	},
	cookies_domain: {
		domain: process.env.NEXT_PUBLIC_BASE_DOMAIN,
		path: '/',
	},
};

export default config;
